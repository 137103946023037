import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
	// Use toast
	const toast = useToast()

	const refUserListTable = ref(null)

	// Table Handlers
	const tableColumns = [
		{ key: 'id', sortable: false, label: 'ID / Тип', visible: true },
		// { key: 'request_type', sortable: false, label: 'Тип'},
		{ key: 'camera_img_url', sortable: false, label: 'Фото', tdClass: 'px-0', visible: true },
		{ key: 'created', sortable: false, label: 'Дата', sortable: true, thStyle: { width: '12em' }, visible: true },
		{ key: 'person_id', sortable: false, label: 'Документ', visible: true },
		{ key: 'exec_time_ms', sortable: false, label: 'Время', sortable: true, visible: true },
		// { key: 'preprocessing_response', sortable: false },
		{ tdClass: 'p-50', key: 'hasFace', sortable: false, label: 'Лицо', visible: true },
		{ tdClass: 'p-50', key: 'isColourful', sortable: false, label: 'Цвет­ность', visible: true },
		{ tdClass: 'p-50', key: 'blurriness', sortable: false, label: 'Размы­тость', visible: true },
		{ tdClass: 'p-50', key: 'recognizedFaces', sortable: false, label: 'Кол-во лиц', visible: true },
		{ tdClass: 'p-50', key: 'eyesOpen', sortable: false, label: 'Глаза', visible: true },
		{ tdClass: 'p-50', key: 'faceAlignment', sortable: false, label: 'Пово­рот', visible: true },
		{ tdClass: 'p-50', key: 'is_recognized', sortable: false, label: 'Comparison', visible: true },
		{ tdClass: 'p-50', key: 'liveness_status', sortable: false, label: 'Live­ness', visible: true },
		{ tdClass: 'p-50', key: 'combo_status', sortable: false, label: 'Combo', visible: true },
		{ key: 'error', sortable: false, label: 'Статус', visible: true },
		{ key: 'extra_info', sortable: false, label: 'Дополнительная информация', visible: true },
	]
	const perPage = ref(20)
	const totalUsers = ref(0)
	const currentPage = ref(1)
	const perPageOptions = [10, 25, 50, 100]
	const searchQuery = ref('')
	const sortBy = ref('created')
	const isSortDirDesc = ref(true)
	const roleFilter = ref(null)
	const planFilter = ref(null)
	const statusFilter = ref(null)
	const passportFilter = ref(null)
	const requestTypeFilter = ref(null)
	const statuses = ref({})

	const dataMeta = computed(() => {
		const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalUsers.value,
		}
	})

	const refetchData = page => {
		if (page) currentPage.value = 1
		refUserListTable.value.refresh()
	}

	let debounce

	store.subscribe((mutation, state) => {
		if (mutation.type === 'appstats/set_start' || mutation.type === 'appstats/set_end') {
			currentPage.value = 1
			clearTimeout(debounce)
			debounce = setTimeout(() => {
				refetchData()
			}, 80)
		}
	})

	watch([roleFilter, statusFilter, requestTypeFilter], () => {
		currentPage.value = 1
	})
	watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter, requestTypeFilter], () => {
		refetchData()
	})

	function getParams() {
		const params = {
			// q: searchQuery.value,
			// perPage: perPage.value,
			start_date: store.state.appstats.start_date,
			end_date: store.state.appstats.end_date,
			page: currentPage.value,
			ordering: (isSortDirDesc.value ? '-' : '') + sortBy.value,
			// sortDesc: isSortDirDesc.value,
			// plan: planFilter.value,
		}

		if (roleFilter.value && statusFilter.value) {
			params.filter = `{"${roleFilter.value}":"${statusFilter.value}"}`
		}
		if (passportFilter.value) {
			params.document = passportFilter.value
		}
		if (requestTypeFilter.value) {
			params.request_type = requestTypeFilter.value
		}

		return params
	}

	const fetchUsers = (ctx, callback) => {
		const params = getParams()

		store
			.dispatch('app-query/fetchStatuses', params)
			.then(response => {
				//TODO: REMOVE WHEN ADDED ON BACKEND
				response.data[10] = 'hasFace'
				response.data[19] = 'hasFace'
				response.data[20] = 'hasFace'
				statuses.value = response.data
			})
			.catch(e => {
				console.warn(e)
				toast({
					component: ToastificationContent,
					props: {
						title: 'Error fetching query list',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
					},
				})
			})
			.then(() => {
				store
					.dispatch('app-query/fetchUsers', params)
					.then(response => {
						const users = response.data.results
						callback(users)
						totalUsers.value = response.data.count
					})
					.catch(e => {
						console.warn(e)
						toast({
							component: ToastificationContent,
							props: {
								title: 'Error fetching status code list',
								icon: 'AlertTriangleIcon',
								variant: 'danger',
							},
						})
					})
			})
	}

	const toISOString = input => {
		const converted = date => date.toISOString()
		if (input instanceof Date) return converted(input)
		if (typeof input !== 'string') return false
		try {
			return converted(new Date(input))
		} catch {
			return false
		}
	}

	const dateOnly = raw => {
		const input = toISOString(raw)
		return input ? input.split('T')[0] : false
	}

	const downloadExcel = () => {
		const params = getParams()

		const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
		if (tz) params.timezone = tz

		store
			.dispatch('app-query/fetchExcel', params)
			.then(response => {
				if (!response.data) throw new Error('Missing response.data')
				return response.data
			})
			.then(data => {
				const blob = new Blob([data])
				const objectURL = window.URL.createObjectURL(blob)

				const start = dateOnly(params.start_date)
				const end = dateOnly(params.end_date)
				const anchor = document.createElement('a')

				anchor.href = objectURL
				anchor.download = `Запросы от ${start} до ${end}.xlsx`
				anchor.click()
				anchor.remove()
				window.URL.revokeObjectURL(objectURL)
			})
			.catch(error => {
				console.warn(error)
				toast({
					component: ToastificationContent,
					props: {
						title: 'Error fetching query list in Excel format',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
					},
				})
			})
	}

	const resolveUserRoleVariant = role => {
		if (role === 'subscriber') return 'primary'
		if (role === 'author') return 'warning'
		if (role === 'maintainer') return 'success'
		if (role === 'editor') return 'info'
		if (role === 'admin') return 'danger'
		return 'primary'
	}

	const resolveUserRoleIcon = role => {
		if (role === 'subscriber') return 'UserIcon'
		if (role === 'author') return 'SettingsIcon'
		if (role === 'maintainer') return 'DatabaseIcon'
		if (role === 'editor') return 'Edit2Icon'
		if (role === 'admin') return 'ServerIcon'
		return 'UserIcon'
	}

	const resolveUserStatusVariant = status => {
		if (status === 'false' || status === false) return 'danger'
		if (status === true || status == 'true') return 'success'
		if (status === null || status == 'null') return 'secondary'
		if (status === '—') return 'secondary'
		return 'primary'
	}

	const resolveLiveness = (id, item) => {
		if (item.request_type == 'comparison_only' && id !== 'is_recognized') {
			return ''
		}
		if (item.request_type == 'comparison_only' && id == 'is_alive') {
			return ''
		}
		if (item.request_type == 'liveness_only' && id == 'is_recognized') {
			return ''
		}
		if (!item.preprocessing_response) return null
		let status = item.liveness_status
		let value
		if (id in item.preprocessing_response.details) {
			value = item.preprocessing_response.details[id]
		} else {
			value = item.preprocessing_response[id]
		}
		if (item.preprocessing_request.allowGrayscale && id == 'isColourful') return '—'
		if (item.preprocessing_request.allowClosedEyes && id == 'eyesOpen') return '—'
		if (item.preprocessing_request.allowMultiface && id == 'recognizedFaces') return '—'
		if (id == statuses.value[status]) return false
		if (id == 'faceAlignment') value = value[Object.keys(value)[0]]
		if (id == 'is_recognized') {
			value = item.preprocessing_response.is_recognized
			if (value === undefined) return '—'
			if (value === false) return false
		}
		if (value === null) return 'null'
		if (value === false) return 'false'
		return true
	}

	return {
		fetchUsers,
		tableColumns,
		perPage,
		currentPage,
		totalUsers,
		dataMeta,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		refUserListTable,

		resolveUserRoleVariant,
		resolveUserRoleIcon,
		resolveUserStatusVariant,
		resolveLiveness,

		refetchData,
		statuses,
		// Extra Filters
		roleFilter,
		planFilter,
		statusFilter,
		passportFilter,
		requestTypeFilter,

		downloadExcel,
	}
}
